<template>
  <v-container :fluid="true" class="synapsa-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="(tab, key) in tabs"
          v-permission="tab.permissions"
          :key="key"
          :text="!tab.ids.includes(activeTab)"
          :color="tab.ids.includes(activeTab) ? 'primary' : ''"
          :outlined="tab.ids.includes(activeTab)"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.ids.includes(activeTab) ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      activeTab: "ruleset",
      tabs: [
        {
          id: "ruleset",
          ids: ["ruleset"],
          name: "auditor.ruleset",
          route: "/auditor/show/ruleset",
          permissions: ["view auditorruleset"],
        },
        {
          id: "cve",
          ids: ["cve"],
          name: "auditor.cve",
          route: "/auditor/show/cve",
          permissions: ["view auditorcve"],
        },
        {
          id: "integrity",
          ids: ["integrity"],
          name: "auditor.integrity",
          route: "/auditor/show/integrity",
          permissions: ["view auditorintegrity"],
        },
        {
          id: "groups",
          ids: ["groups"],
          name: "auditor.groups",
          route: "/auditor/show/groups",
          permissions: ["view auditorgroups"],
        },
        {
          id: "templates",
          ids: ["templates", "auditorTemplateRules"],
          name: "auditor.templates",
          route: "/auditor/show/templates",
          permissions: ["view auditortemplates"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
